<a
	class="header-button sl-flexbox sl-flexbox-align-center sl-flexbox-justify-center sl-flexbox-gap-4"
	[class.active-link]="isActiveRoute()"
	[class.header-button-icon]="icon()"
	[routerLink]="expandable() ? null : route()"
	[queryParams]="queryParams()">
	{{ text() }}
	@if (icon()) {
		<mat-icon class="material-symbols-outlined mat-icon-20">{{ icon() }}</mat-icon>
	}
	<p-tag
		*ngIf="tag()"
		[value]="tag()"
		severity="warning"
		class="ml-xs"
		styleClass="sl-v1-tag sl-v1-tag-xs sl-v1-tag-outlined"
		[rounded]="true">
	</p-tag>
	@if (expandable()) {
		<mat-icon class="mat-icon-20 expand-icon">expand_more</mat-icon>
	}
	@if (badge()) {
		<span class="header-button-badge">{{ badge() }}</span>
	}
</a>
