import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, from, map, Observable, of, switchMap, take } from 'rxjs';
import { AuthService } from 'src/app/features/auth/services/auth.service';

import { LandingPages, LandingPageValue } from '@@core/models/landing-page.model';
import { AuthStore } from '@@shared/stores/auth-store/stores/auth.store';
import { selectUserPreferencesState, UserPreferenceKey } from '@@shared/user-preferences';
import * as UserPreferencesActions from '@@shared/user-preferences/actions/user-preferences.actions';


export const canActivateSSOTokenGuard = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
	const url = state?.url;
	const authService = inject(AuthService);
	const router = inject(Router);
	const store = inject(Store);
	const authStore = inject(AuthStore);

	if (url?.includes('code=') || url?.includes('sso-login')) {
		const rawToken = url.split('=')[1];

		if (rawToken && !authStore.isAuthenticated()) {
			const token = rawToken.split('&')[0]; // CLEAN the token from other parameters

			return authService.loginWithToken(token)
				.pipe(
					switchMap(response => {
						if (response.success) {
							store.dispatch(UserPreferencesActions.getUserPreference());

							if (next.queryParams.redirect && !['/', '/login'].includes(decodeURIComponent(next.queryParams.redirect as string))) {
								return from(router.navigateByUrl(decodeURIComponent(next.queryParams.redirect as string)));
							} else {
								return store.select(selectUserPreferencesState)
									.pipe(
										filter(storeState => storeState.loaded),
										take(1),
										map(storeState => {
											const landingPage = storeState.values[UserPreferenceKey.LandingPage];

											return router.navigateByUrl(LandingPages[landingPage]?.route || LandingPages[LandingPageValue.Dashboard].route);
										}),
										map(() => true)
									);
							}
						} else {
							return authService.logout(true)
								.pipe(
									map(() => true)
								);
						}
					})
				);
		}
	}

	return of(true);
};
