import { createReducer, on } from '@ngrx/store';

import * as GlobalActions from '../actions/global-store.actions';
import { initialGlobalState } from '../models/global-store.model';


export const globalStoreReducer = createReducer(
	initialGlobalState,
	on(GlobalActions.globalStoreSetLoginAsUserSuccess, (state, { userId }) => ({ ...state, loginAsUserId: userId })),
	on(GlobalActions.globalStoreLoadImpersonatedUserIdSuccess, (state, { userId }) => ({ ...state, loginAsUserId: userId })),
);
