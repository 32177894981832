<button mat-button #trigger class="hidden-trigger" [hidden]="true" (click)="visible=true"></button>
<p-dialog
	styleClass="manual-test-overlay sl-typography-body"
	appendTo="body"
	position="top-right"
	[closable]="false"
	[resizable]="false"
	[keepInViewport]="true"
	[(visible)]="visible"
	(onShow)="show()"
	(onHide)="hide()">
	<ng-template pTemplate="header">
		<ng-container *ngIf="!isActive && !submitted">
			<div class="overlay-header sl-typography-body-large">New manual test</div>
		</ng-container>
		<ng-container *ngIf="isActive && !runningTest?.ended">
			<div class="overlay-header sl-typography-body-large progress big">
				<div class="led sl-grid-columns sl-align-items-vertical-center" [ngClass]="{ 'running': !runningTest.paused }"></div>
				<div class="timer"><sl-stopwatch #sw [timeState]="stopWatchTimeState" [startOnCreate]="!runningTest?.paused" (valueChange)="testDurationChange($event)" (timeStateChanged)="stopwatchTimeStateChanged($event)"></sl-stopwatch></div>
				<div class="stopwatch-status">{{ sw.isRunning ? 'Collecting...' : 'Paused' }}</div>
				<div class="video-buttons sl-grid-columns">
					<mat-icon (click)="restart()">replay</mat-icon>
					<mat-icon *ngIf="!sw?.isRunning" (click)="start()">play_arrow</mat-icon>
					<mat-icon *ngIf="sw?.isRunning" (click)="pause()">pause</mat-icon>
					<mat-icon (click)="end()">stop</mat-icon>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="isActive && runningTest?.ended && !requestingCancel">
			<div class="overlay-header sl-typography-body-large big">Submit manual test</div>
		</ng-container>
		<ng-container *ngIf="isActive && runningTest?.ended && requestingCancel">
			<div class="overlay-header sl-typography-body-large">Cancel manual test</div>
		</ng-container>
		<ng-container *ngIf="!isActive && submitted">
			<div class="overlay-header sl-typography-body-large">New manual test submitted</div>
		</ng-container>
	</ng-template>
	<ng-template pTemplate="content">
		<div *ngIf="!isActive && !submitted" class="wrapper form-wrapper">
			<div class="overlay-body setup">
				<form class="form" [formGroup]="manualTestForm">
					<mat-radio-group class="sl-grid-columns test-by-options" formControlName="context" name="test-context" (change)="testContextChange($event)">
						<mat-radio-button
							value="byAppBranchBuild"
							disableRipple="true">By app/branch/build</mat-radio-button>
						<mat-radio-button
							value="byLab"
							disableRipple="true">By lab</mat-radio-button>
					</mat-radio-group>
					<div class="test-what-fields">
						<div [hidden]="testContext === 'byLab'">
							<div class="field-caption sl-typography-body-medium">App</div>
							<sl-select
								[options]="apps$ | async"
								[config]="appSelectConfig"
								formControlName="app"
								(selectionChanged)="appSelectionChanged($event)">
							</sl-select>
							<div class="field-caption sl-typography-body-medium">Branch</div>
							<sl-select
								[options]="branches$ | async"
								[config]="branchSelectConfig"
								formControlName="branch"
								(selectionChanged)="branchSelectionChanged($event)">
							</sl-select>
							<div class="field-caption sl-typography-body-medium">Build</div>
							<sl-select
								[options]="builds$ | async"
								[config]="buildSelectConfig"
								formControlName="build"
								(selectionChanged)="buildSelectionChanged($event)">
							</sl-select>
							<ng-container *ngIf="(labIds$ | async).length">
								<div class="field-caption sl-typography-body-medium">Lab ID</div>
								<sl-select
									[options]="labIds$ | async"
									[config]="labIdSelectConfig"
									formControlName="labId"
									(selectionChanged)="labIdSelectionChanged($event)">
								</sl-select>
							</ng-container>
							<ng-container *ngIf="!(labIds$ | async).length || typedLabIdSelected">
								<div class="field-caption sl-typography-body-medium">Lab ID (enter an active lab ID)</div>
								<div class="form-field sl-grid-rows">
									<input type="text" class="sl-typography-body" placeholder="Lab ID" autocomplete="off" formControlName="typedLabId">
								</div>
								<div *ngIf="!(labIds$ | async).length" class="lab-id-message">
									No active labs detected with the provided app/branch/build. The lab ID you provide should match the lab ID of the process under test.
									If the process under test is not running with this lab ID, you will not capture coverage for your tests.
								</div>
								<div *ngIf="typedLabIdSelected" class="lab-id-message">
									The lab ID you provide should match the lab ID of the process under test. If the process under test is not running with this lab ID,
									you will not capture coverage for your tests.
								</div>
							</ng-container>
						</div>
						<div [hidden]="testContext === 'byAppBranchBuild'">
							<div class="field-caption sl-typography-body-medium">Lab</div>
							<sl-select
								[options]="labs$ | async"
								[config]="labSelectConfig"
								formControlName="lab"
								(selectionChanged)="labSelectionChanged($event)">
							</sl-select>
							<div class="lab-context">
								<div class="field-caption sl-typography-body-medium">App</div>
								<input type="text" class="sl-typography-body" formControlName="labContextAppName">
								<div class="field-caption sl-typography-body-medium">Branch</div>
								<input type="text" class="sl-typography-body" formControlName="labContextBranchName">
								<div class="field-caption sl-typography-body-medium">Build</div>
								<input type="text" class="sl-typography-body" formControlName="labContextBuildName">
							</div>
						</div>
					</div>
					<div class="test-details-caption">Test Details</div>
					<div class="test-details-fields">
						<div class="form-field sl-grid-rows">
							<span class="caption sl-typography-body-medium">Test Stage</span>
							<p-dropdown *ngIf="lockTestStageNameSignal$()"
								#testStageDropdown
								[options]="testStageNames$ | async"
								tooltipPosition="bottom"
								formControlName="testStages"
								placeholder="Select test stage"
								styleClass="sl-v1-dropdown"
								panelStyleClass="sl-v1-dropdown-panel manual-test full-width-search">
								<ng-template let-item pTemplate="item">
									<ng-container>
										{{ item }}
									</ng-container>
								</ng-template>
								<ng-template let-item pTemplate="selectedItem">
									<ng-container>
										{{ item }}
									</ng-container>
								</ng-template>
							</p-dropdown>
							<input *ngIf="!lockTestStageNameSignal$()" type="text" class="sl-typography-body" placeholder="Test Stage" autocomplete="off" formControlName="testStage">
						</div>
						<div class="form-field sl-grid-rows test-name-section">
							<ng-container *ngIf="!recommendedTestListFeatureActiveSignal$() || !formState.recommendedTests?.items?.length; else testFromList">
								<ng-container *ngIf="recommendedTestListFeatureActiveSignal$()">
									<span class="caption sl-typography-body-medium">Please Select</span>
									<mat-radio-group
										class="sl-grid-columns test-source-options"
										formControlName="testSource"
										name="test-source"
										(change)="testSourceChange($event)">
										<mat-radio-button
											value="list"
											[disableRipple]="true">Test List</mat-radio-button>
										<mat-radio-button
											value="single"
											[disableRipple]="true">Single Test</mat-radio-button>
									</mat-radio-group>
								</ng-container>
								<ng-template [ngTemplateOutlet]="recommendedTestListFeatureActiveSignal$() && isTestFromList ? testSourceList : testSourceSingle"></ng-template>
								<ng-template #testSourceList>
									<div class="test-list-buttons sl-grid-columns">
										<div class="test-list-button upload-file" (click)="uploadFileInput.click()">
											<input #uploadFileInput class="upload-file-input" type="file" accept=".csv" (change)="onUploadFile($event.target.files)">
											<mat-icon class="test-list-icon upload-file-icon">upload_file</mat-icon>
											<div class="button-caption">Upload from file</div>
										</div>
										<div class="test-list-button get-recommendations" (click)="onGetRecommendedTestsClick()">
											<mat-icon class="test-list-icon recommended-list-icon">list_alt</mat-icon>
											<div class="button-caption">Get recommendations</div>
										</div>
									</div>
								</ng-template>
								<ng-template #testSourceSingle>
									<span class="caption sl-typography-body-medium">Test Name</span>
									<input class="single-test-input sl-typography-body" type="text" placeholder="Test" autocomplete="off" formControlName="test">
								</ng-template>
							</ng-container>
							<ng-template #testFromList>
								<div class="test-from-list sl-grid-rows">
									<span class="caption sl-typography-body-medium">Test List</span>
									<div class="test-list-source sl-grid-columns">
										<div *ngIf="!!formState.recommendedTests.fileName" class="test-list-source-name ellipsisize file" [matTooltip]="formState.recommendedTests.fileName" (click)="onViewTestList()">{{ formState.recommendedTests.fileName }}</div>
										<div *ngIf="!formState.recommendedTests.fileName" class="test-list-source-name ellipsisize recommendations" [matTooltip]="formState.recommendedTests.fileName" (click)="onViewTestList()">Recommendations</div>
										<mat-icon class="delete-list-icon clickable" (click)="onDeleteTestListClick()">delete_outline</mat-icon>
									</div>
								</div>
							</ng-template>
						</div>
						<div *ngIf="includedTests?.length && !formState.recommendedTests.allTestsEnded" class="form-field sl-grid-rows">
							<span class="caption sl-typography-body-medium">Next test to execute</span>
							<ng-template [ngTemplateOutlet]="testNameAndIndex"></ng-template>
						</div>
					</div>
				</form>
				<div class="actions">
					<span class="sl-typography-link-action" (click)="visible=false">Cancel</span>
					<button mat-button class="new-design primary-button" [disabled]="isActive || !manualTestForm.valid || (isTestFromList && !includedTests?.length)" (click)="save()">{{ saveButtonCaption }}</button>
				</div>
			</div>
		</div>
		<div *ngIf="isActive && !runningTest?.ended" class="wrapper collecting-wrapper big">
			<div class="overlay-body">
				<ng-template [ngTemplateOutlet]="testNameAndIndex"></ng-template>
			</div>
		</div>
		<div *ngIf="isActive && runningTest?.ended && !requestingCancel" class="wrapper result-wrapper big">
			<div class="resume clickable" (click)="resume()">
				<mat-icon class="back-icon">arrow_back_ios</mat-icon>
				<span class="resume-caption">Resume Test</span>
			</div>
			<div class="overlay-body">
				<ng-template [ngTemplateOutlet]="testNameAndIndex"></ng-template>
				<div class="test-result">
					<div class="test-result-title">Select the result of your test:</div>
					<div class="test-result-selection">
						<div class="passed">
							<button
								type="button"
								class="button-passed"
								[ngClass]="{ selected: runningTest.testResult === TestResult.Passed }"
								(click)="onChangeTestResult(TestResult.Passed)">
								<mat-icon>done</mat-icon>
							</button>
							<div class="selection-text">Passed</div>
						</div>
						<div class="failed">
							<button
								type="button"
								class="button-failed"
								[ngClass]="{ selected: runningTest.testResult === TestResult.Failed }"
								(click)="onChangeTestResult(TestResult.Failed)">
								<mat-icon>close</mat-icon>
							</button>
							<div class="selection-text">Failed</div>
						</div>
					</div>
				</div>
				<div class="description sl-typography-body-small">
					Coverage calculation take over a minute.<br>
					You will be able to view it on the dashboard later on.
				</div>
				<div class="actions">
					<span class="sl-typography-link-action" (click)="onRequestCancel()">Cancel</span>
					<div class="submit-section" [ngClass]="{ 'test-from-list': isTestFromList }">
						<button mat-button class="new-design primary-button submit-start-next" [disabled]="runningTest.testResult === undefined" (click)="submit()">{{ submitButtonCaption }}</button>
						<button *ngIf="isTestFromList" mat-button class="new-design primary-button show-more-submit-options" [disabled]="runningTest.testResult === undefined" (click)="showMoreSubmitOptions = !showMoreSubmitOptions"><mat-icon>expand_more</mat-icon></button>
						<div *ngIf="showMoreSubmitOptions" class="more-submit-options">
							<button type="button" class="button-submit-return-list" (click)="onSubmitAndBackToList()">SUBMIT AND BACK TO LIST</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="isActive && runningTest?.ended && requestingCancel" class="wrapper cancel-test-wrapper">
			<div class="overlay-body">
				<div>Are you sure you want to cancel your test?</div>
				<div class="second-line">You will not be able to recover it.</div>
				<div class="actions">
					<span class="sl-typography-link-action" (click)="requestingCancel = false">Back</span>
					<button mat-button class="new-design primary-button" (click)="cancel()">Yes, cancel test</button>
				</div>
			</div>
		</div>
		<div *ngIf="!isActive && submitted" class="wrapper test-submitted">
			<div class="overlay-body">
				<div class="icon-section">
					<mat-icon>sentiment_very_satisfied</mat-icon>
					<div>Awesome!</div>
				</div>
				<div class="info-section">Your tests were submitted successfully and the data will be presented soon on your SeaLights dashboard.</div>
				<div class="actions">
					<button *ngIf="isTestFromList" mat-button class="new-design secondary" (click)="onBackToListAfterFinished()">BACK TO LIST</button>
					<button *ngIf="!isTestFromList" mat-button class="new-design secondary" (click)="onBackToSetupAfterFinished()">BACK TO SETUP</button>
					<button mat-button class="new-design primary-button done-button" (click)="onDoneLastStep()">Done</button>
				</div>
			</div>
		</div>
		<sl-test-list-overlay
			#testListOverlay
			*ngIf="!!formState.recommendedTests?.items?.length"
			class="testListOverlay"
			[testList]="formState.recommendedTests.items"
			[fromRecommendedTests]="!formState.recommendedTests.fileName"
			[isFinalList]="currentTestIndex > 0 || formState.recommendedTests?.allTestsEnded"
			(testListUpdated)="recommendedTestListUpdated($event)"
			(loadMoreClicked)="getMoreRecommendedTests()">
		</sl-test-list-overlay>
	</ng-template>
</p-dialog>

<ng-template #buildItemTemplate let-item="item">
	<ng-container>
		{{ item.buildName }} ({{ item.generated | date:'MMM dd, yyyy' }})
	</ng-container>
</ng-template>

<ng-template #LabIdItemTemplate let-item="item">
	<ng-container>
		<ng-container *ngIf="item.disabled"><hr></ng-container>
		<ng-container *ngIf="!item.disabled">{{ item.name }}</ng-container>
	</ng-container>
</ng-template>

<ng-template #testNameAndIndex>
	<div class="test-name-and-index">
		<span class="test-name ellipsisize" [title]="formState.test ?? nextTestName">{{ formState.test ?? nextTestName }}</span>
		<span *ngIf="!!formState.recommendedTests?.items?.length" class="test-index">({{ currentTestIndex !== -1 ? currentTestIndex + 1 : 1}} out of {{ includedTests.length }})</span>
	</div>
</ng-template>
