import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs';

import * as GlobalStoreActions from '../actions/global-store.actions';

@Injectable({
	providedIn: null
})
export class GlobalStoreEffects {
	readonly setImpersonateUser$ = createEffect(() =>
		inject(Actions).pipe(
			ofType(GlobalStoreActions.globalStoreSetLoginAsUser),
			tap(({ userId }) => {
				if (userId) {
					localStorage.setItem('impersonatedUserId', userId);
				} else {
					localStorage.removeItem('impersonatedUserId');
				}
			}),
			map(({ userId }) => GlobalStoreActions.globalStoreSetLoginAsUserSuccess({ userId }))
		));

	readonly loadImpersonatedUser$ = createEffect(() =>
		inject(Actions).pipe(
			ofType(GlobalStoreActions.globalStoreLoadImpersonatedUserId),
			map((() => GlobalStoreActions.globalStoreLoadImpersonatedUserIdSuccess({ userId: localStorage.getItem('impersonatedUserId') })))
		)
	);
}
