import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	inject,
	input,
	OnInit,
	signal
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { NavigationEnd, Params, Router, RouterModule } from '@angular/router';
import { TagModule } from 'primeng/tag';

@Component({
	selector: 'sl-header-button',
	templateUrl: './header-button.component.html',
	styleUrl: './header-button.component.scss',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, TagModule, RouterModule, MatIcon],
})
export class HeaderButtonComponent implements OnInit {
	route = input<string>();
	text = input<string>();
	tag = input<string>();
	icon = input<string>();
	badge = input<string>();
	expandable = input<boolean>();
	queryParams = input<Params>();

	isActiveRoute = signal(false);

	readonly #router = inject(Router);

	ngOnInit(): void {
		this.#setIsActiveRoute();
		this.#router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.#setIsActiveRoute();
			}
		});
	}

	#checkActiveRoute(routerLink?: string): boolean {
		if (!routerLink) {
			return false;
		}

		const urlTree = this.#router.createUrlTree([routerLink], {
			queryParams: this.queryParams()
		});

		return this.#router.isActive(urlTree, {
			fragment: 'ignored',
			matrixParams: 'ignored',
			queryParams: 'subset',
			paths: 'subset'
		});
	}

	#setIsActiveRoute(): void {
		this.isActiveRoute.set(this.#checkActiveRoute(this.route()));
	}
}
