<section class="change-password-wrapper">
	<form class="sl-flexbox-column sl-flexbox-justify-space-around sl-flexbox-align-center" [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
		<div *ngIf="!changePasswordStoreSignal$().successMessage" class="change-password-fields sl-flexbox-column sl-flexbox-justify-space-around sl-flexbox-align-center">
			<mat-form-field>
				<input matInput type="password" placeholder="Old Password" formControlName="oldPassword" autocomplete="current-password">
			</mat-form-field>

			<mat-form-field>
				<input matInput type="password" placeholder="New Password" formControlName="newPassword" autocomplete="new-password">
			</mat-form-field>

			<mat-form-field>
				<input matInput type="password" placeholder="Retype Password" formControlName="confirmPassword" autocomplete="new-password">
			</mat-form-field>
		</div>

		<div *ngIf="!changePasswordStoreSignal$().successMessage" class="change-pass-validation-box">
			<div class="validation-box-title">Password must follow these conditions:</div>
			<section class="validation-box-rules">
				<ul class="sl-flexbox-column">
					<li *ngFor="let rule of validationRules"
						class="policy-item"
						[class.error]="changePasswordForm.controls.newPassword.errors?.hasOwnProperty(rule.key)"
						[class.success]="!changePasswordForm.controls.newPassword.errors?.hasOwnProperty(rule.key) && !changePasswordForm.controls.newPassword.pristine">
						<span class="text">{{ rule.rule }}</span>
					</li>
					<li *ngIf="changePasswordForm.controls.newPassword.errors?.zxcvbn" class="policy-item error">
						<span class="text">
							{{
									changePasswordForm.controls.newPassword.errors?.zxcvbn?.warning
								||	changePasswordForm.controls.newPassword.errors?.zxcvbn?.suggestions[0]
								||	'Password is in dictionaries'
							}}
						</span>
					</li>
					<li *ngIf="changePasswordForm.errors?.matchPasswords" class="policy-item error">
						<span class="text">{{ changePasswordForm.errors.matchPasswords }}</span>
					</li>
					<li *ngIf="changePasswordStoreSignal$().wrongOldPassword" class="policy-item error">
						<span class="text">Wrong old password</span>
					</li>
					<li *ngIf="changePasswordStoreSignal$().serverError" class="policy-item error">
						<span class="text">Server error</span>
					</li>
				</ul>
			</section>
		</div>

		<div class="submit-buttons" *ngIf="!changePasswordStoreSignal$().successMessage">
			<button type="button" [class.untouched]="changePasswordForm.untouched" mat-fab (click)="closeDialog()">
				<mat-icon>keyboard_backspace</mat-icon>
			</button>

			<button type="submit" [class.untouched]="changePasswordForm.untouched" mat-fab [disabled]="!changePasswordForm.valid" [class.ready]="changePasswordForm.valid">
				<mat-icon class="forward">keyboard_backspace</mat-icon>
			</button>
		</div>

		<div *ngIf="changePasswordStoreSignal$().successMessage" class="success-message">PASSWORD CHANGED SUCCESSFULLY</div>
		<div *ngIf="changePasswordStoreSignal$().successMessage"><button class="login-button ready" mat-fab>
				<mat-icon>done</mat-icon>
			</button>
		</div>
	</form>
</section>
