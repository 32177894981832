import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { delay, from, map, Observable, of, switchMap } from 'rxjs';

import { FeatureService } from '@@core/services/features.service';
import { LDFeatureKey } from '@@core/services/launch-darkly.service';
import { PermissionsService } from '@@settings/services/permissions.service';
import { AuthStore } from '@@shared/stores/auth-store/stores/auth.store';

import { AuthService } from './auth.service';

export const canActivateAuthGuard = (route: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean | UrlTree> => {
	const url = state?.url;
	const nonCognitoAuth = inject(FeatureService).isFeatureOn(LDFeatureKey.NON_COGNITO_AUTH);
	const permissionsService = inject(PermissionsService);
	const authStore = inject(AuthStore);

	if (!url) {
		return redirectToLogin(url);
	}

	if (authStore.isTokenExpired()) {
		if (nonCognitoAuth) {
			return redirectToLogin(url);
		} else {
			return handleExtendTokenResponse(url);
		}
	} else {
		return permissionsService.getUserProfile()
			.pipe(
				map(() => true)
			);
	}
};

const redirectToLogin = (url: string): Observable<boolean> => {
	const authService = inject(AuthService);
	const router = inject(Router);

	return authService.logout(false)
		.pipe(
			delay(1),
			switchMap(() => from(router.navigate(['/login'], { queryParams: { redirect: url } })))
		);
};

const handleExtendTokenResponse = (url: string): Observable<boolean> => inject(AuthService).extendToken()
	.pipe(
		switchMap(response => {
			if (response) {
				if (response.success) {
					return of(true);
				} else if (!response.success) {
					if (response.message.status === 401) {
						// this._logService.info('Unsuccessful Token Extend : Not authorized');
					} else if (response.message.status === 403) {
						// this._logService.info('Unsuccessful Token Extend : Forbidden');
					} else if (response.message.status === 500) {
						// this._logService.error('Unsuccessful Token Extend : Internal server error');
					} else if (response.message.status === null) {
						// this._logService.error(`Unsuccessful Token Extend : ${response.message.statusText}`);
					}

					return redirectToLogin(url);
				}
			} else {
				return redirectToLogin(url);
			}
		})
	);
